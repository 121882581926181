import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import * as styles from './series-page.module.css'
import { Badge } from '../../components/tags/Tag'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { rhythm } from '../../utils/typography'

export const SeriesPage = ({
  name,
  title,
  subtitle,
  image,
  imageAlt,
  children,
}) => (
  <StaticQuery
    query={graphql`
      query JavaScriptmancySeriesQuery {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: ASC }
          filter: {
            fields: { isBlogArticle: { eq: true } }
            frontmatter: { series: { ne: null } }
          }
          limit: 1000
        ) {
          edges {
            node {
              html
              fields {
                slug
                isBlogArticle
              }
              frontmatter {
                title
                date
                categories
                description
                series
                cover_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                cover_image_alt
                cover_image_title
                cover_image_width
                video
              }
              timeToRead
            }
          }
        }
      }
    `}
    render={(data) => {
      const fullTitle = `${title} - ${subtitle}`
      const articles = data.allMarkdownRemark.edges
        .map((e) => e.node)
        .filter((n) => n.frontmatter.series === name)

      // Pick the cover image from the first article
      const coverImage = articles[0].frontmatter.cover_image
      // coverImage is going to be an ImageSharp node that represents an image.
      // The getImage helper returns a gatsbyImageData object for that node that
      // one can use with the GatsbyImage component.
      //
      // See https://www.gatsbyjs.com/docs/tutorial/part-7/ for more information.
      const image = getImage(coverImage)

      // TODO(breaks-layout-mobile): the cover image breaks the layout of mobile. Fix this
      return (
        <article className={styles.series}>
          <SEO title={title} />
          <Badge className={styles.badge}>SERIES</Badge>
          <h1 className={styles.title}>
            {title} <small>{subtitle}</small>
          </h1>
          <header className={styles.header}>
            <section className={styles.image}>
              <GatsbyImage image={image} alt={imageAlt} title={fullTitle} />
            </section>
            <section className={styles.description}>{children}</section>
          </header>
          <SeriesArticles articles={articles} />
        </article>
      )
    }}
  />
)

const SeriesArticles = ({ articles }) => (
  <article className={styles.articles}>
    <h2
      style={{
        paddingBottom: rhythm(1 / 2),
        borderBottom: '0.5px dashed #dedede',
      }}
    >
      Articles in the Series
    </h2>
    <ol>
      {articles.map((a, index) => (
        <li key={index}>
          <Link className={styles.article} to={a.fields.slug}>
            <header>
              <aside className={styles.number}>{`${index + 1}.`}</aside>
              <h3>{a.frontmatter.title}</h3>
            </header>
            <section>
              <p>{a.frontmatter.description}</p>
            </section>
          </Link>
        </li>
      ))}
    </ol>
  </article>
)
