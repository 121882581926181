import React from 'react'
import Layout from '../../components/layout/Layout'
import { SeriesPage } from '../../components/series/SeriesPage'

const TypeScriptIntroductionSeries = () => {
  const name = 'typescript-introduction'
  const title = 'Introduction to TypeScript'
  const subtitle = 'JavaScript + Types = Awesome Developer Productivity'
  const image = '/images/typescript-official.png'
  const imageAlt = 'The TypeScript word in white with a dark blue background.'

  return (
    <Layout>
      <SeriesPage
        name={name}
        title={title}
        subtitle={subtitle}
        image={image}
        imageAlt={imageAlt}
      >
        <p>
          TypeScript is a superset of JavaScript that adds type annotations and,
          thus, static typing on top of JavaScript. Using these type annotations
          the TypeScript compiler can obtain an improved understanding of your
          code and give you and your team a myriad of productivity improvements.
        </p>
        <p>
          This series offers an introduction to TypeScript, how it relates to
          JavaScript and what are its advantages and disadvantages you need to
          consider before using TypeScript in your next project.
        </p>
      </SeriesPage>
    </Layout>
  )
}

export default TypeScriptIntroductionSeries
